<template>
  <div class="views-page p-4">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    />
    <div
      :class="[messages.main_color, 'info-line']"
    />
    <div class="column p-0 is-size-6 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        class="tag-mobile is-size-6 tag px-4 py-4 mr-3 mb-2"
        v-for="(Msg, id) in messages.cards"
        :key="Msg.id"
        :class="Msg.color"
      >{{ Msg.name }}</span>
    </div>
    <div>
      <h1 class="has-text-centered has-text-weight-semibold is-size-5">{{ messages.device_name }}</h1>
      <div class="is-divider my-4"></div>
    </div>
    <div>
      <template v-if="!isLoading">
        <Trends
          :temperatures="temperatures"
          :predictions="predictions"
          :prediction-channels="predictionChannels"
          :agents="agents"
          :checked-prediction-names="checkedPredictionNames"
          :graph-data="graph_data"
          :graph-data-with-predictions="graph_data_with_predictions"
          :warnings="warnings"
          :levels="levels"
          :show-temperature-text="showTemperatureText"
          :selected-trends="trendNames.filter(name => selectedTrendNameIds.includes(name.id)).map(name => name.name)"
          @legendSelected="onLegendSelected"
        />
      </template>
      <template v-else>
        <div id="chartdiv" />
      </template>
    </div>
    <div class="mx-3">
      <b-message v-if="!isLoading && predictions.length === 0" type="is-warning">
        Прогноз невозможен. Недостаточно данных
      </b-message>
      <div class="is-flex is-flex-wrap-wrap">
        <div class="is-flex is-flex-direction-column mr-4" style="flex: 0 1 220px">
          <div class="is-flex is-flex-direction-column mb-3">
            <p class="has-text-centered mb-2" style="line-height: 20px">Период отображения данных:</p>
<!--            <a-->
<!--              :class="{highlight:1 === selected}"-->
<!--              class="button is-small mr-1"-->
<!--              @click="selected !== 1 && fetchByDate(1)"-->
<!--            >Сегодня-->
<!--            </a>-->
<!--            <a-->
<!--              :class="{highlight:7 === selected}"-->
<!--              class="button is-small mr-1"-->
<!--              @click="selected !== 7 && fetchByDate(7)"-->
<!--            >Неделя-->
<!--            </a>-->
<!--            <a-->
<!--              :class="{highlight:30 === selected}"-->
<!--              class="button is-small mr-1 mb-5"-->
<!--              @click="selected !== 30 && fetchByDate(30)"-->
<!--            >Месяц-->
<!--            </a>-->
            <b-field>
              <b-datepicker
                v-model="dates"
                range
                :first-day-of-week="1"
                icon="calendar-days"
                icon-pack="fas"
                size="is-small"
                placeholder="Выберите даты"
                position="is-top-right"
              >
                <div class="columns is-multiline is-gapless">
                  <div class="column is-half" style="padding: 2px !important;">
                    <b-button
                      class="column is-half"
                      style="height: auto; width: 100%"
                      label="Сегодня"
                      type="is-info"
                      size="is-small"
                      @click="handleDatesChange(1, 'day')"
                    />
                  </div>
                  <div class="column is-half" style="padding: 2px !important;">
                    <b-button
                      class="column is-half"
                      style="height: auto; width: 100%"
                      label="Неделя"
                      type="is-info"
                      size="is-small"
                      @click="handleDatesChange(7 , 'day')"
                    />
                  </div>
                  <div class="column is-half" style="padding: 2px !important;">
                    <b-button
                      class="column is-half"
                      style="height: auto; width: 100%"
                      label="Месяц"
                      type="is-info"
                      size="is-small"
                      @click="handleDatesChange(1, 'month')"
                    />
                  </div>
                  <div class="column is-half" style="padding: 2px !important;">
                    <b-button
                      class="column is-half"
                      style="height: auto; width: 100%"
                      label="Очистить"
                      size="is-small"
                      type="is-danger"
                      outlined
                      @click="dates = []" />
                  </div>
                </div>
              </b-datepicker>
            </b-field>
<!--            <b-field>-->
<!--              <b-datepicker-->
<!--                :first-day-of-week="1"-->
<!--                icon-pack="fas"-->
<!--                icon-next="chevron-right"-->
<!--                icon-prev="chevron-left"-->
<!--                placeholder="до"-->
<!--                append-to-body-->
<!--                size="is-small"-->
<!--                locale="ru-RU"-->
<!--                icon="calendar-days"-->
<!--                editable-->
<!--              >-->
<!--              </b-datepicker>-->
<!--            </b-field>-->
          </div>
          <div class="is-flex is-flex-direction-column mb-3">
            <p>Показывать уровни:</p>
            <div class="warning">
              <label class="checkbox">
                <input
                  type="checkbox"
                  value="Alarm"
                  v-model="levels"
                >
                тревога
                <span class="line-alarm"></span>
              </label>
            </div>
            <div class="warning">
              <label class="checkbox">
                <input
                  type="checkbox"
                  value="Warning"
                  v-model="levels"
                >
                предупреждение
                <span class="line-warning"></span>
              </label>
            </div>
          </div>
        </div>
        <div style="flex: 1">
          <div class="is-flex is-flex-direction-column is-flex-wrap-wrap mt-2">
            <div class="is-flex is-flex-direction-column">
              <p class="mb-3">Показывать прогноз для датчиков:</p>
              <div class="is-flex is-justify-content-space-between">
                <b-field class="button-group" group-multiline grouped>
                  <b-checkbox-button
                    v-for="(value) in predictionChannels"
                    :key="value"
                    v-model="checkedPredictionNames"
                    :native-value="value"
                    type="is-danger"
                  >
                    <span>{{ value }}</span>
                  </b-checkbox-button>
                </b-field>
              </div>
              <div class="mt-4">
                <b-radio
                  v-model="type"
                  name="type"
                  native-value="0"
                  @input="fetchByDate"
                  :disabled="predictions.length === 0"
                >
                  Час
                </b-radio>
                <b-radio
                  v-model="type"
                  name="type"
                  native-value="1"
                  @input="fetchByDate"
                  :disabled="predictions.length === 0"
                >
                  День
                </b-radio>
                <b-radio
                  v-model="type"
                  name="type"
                  native-value="2"
                  @input="fetchByDate"
                  :disabled="predictions.length === 0"
                >
                  Неделя
                </b-radio>
                <b-radio
                  v-model="type"
                  name="type"
                  native-value="3"
                  @input="fetchByDate"
                  :disabled="predictions.length === 0"
                >
                  Месяц
                </b-radio>
              </div>
            </div>
            <div style="flex: 1">
              <p>для датчиков:</p>
              <b-select
                v-model="selectedTrendName"
                :loading="namesLoading"
                placeholder="выберите"
                @input="fetchWarnings"
              >
                <option
                  v-for="agent in trendNames"
                  :value="agent.id"
                  :key="agent.id"
                >
                  {{ agent.name }}
                </option>
              </b-select>
              <!--            <select v-model="selectedTrendName">-->
              <!--              <option-->
              <!--                v-for="agent in trendName"-->
              <!--                :key="agent"-->
              <!--              >{{ agent }}-->
              <!--              </option>-->
              <!--            </select>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Trends from "@/components/Trends/Trends-Graph"
import axios from "axios"
import dayjs from "dayjs"

const CancelToken = axios.CancelToken

export default {
  components: {
    Trends,
  },
  props: ["deviceName", "device_id"],
  data: () => ({
    graph_data: [],
    graph_data_with_predictions: [],
    graph_data_without_warnings: [],
    type: 0,
    isLoading: true,
    namesLoading: true,
    defaultDate: null,
    messages: [],
    warnings: [],
    predictions: [],
    predictionChannels: [],
    agents: [],
    trendNames: [],
    dates: [],
    date: {
      from: null,
      to: null
    },
    selected: 1,
    selectedTrendName: "",
    levels: ["Alarm", "Warning"],
    currentData: null,
    checkedPredictionNames: [],
    buttonsCount: 4,
    // buttons: ["vib1", "vib2", "vib3", "vib4", "vib5", "vib6", "vib7", "vib8"],
    isActiveBtn: false,
    temperatures: [],
    cancelRequests: CancelToken.source(),
    showTemperatureText: false,
    // types: ['RMS(v)', 'RMS(a)', 'DKW(a)', 'Peak(a)'],
    selected_type: 'RMS(v)',
    selectedTrendNameIds: []
  }),
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    GetMessagesData(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.messages = newValue || []
        this.buttonsCount = newValue?.module_count || 4
        this.showTemperatureText = newValue?.temperature_count > 0
      }
    },
    // selectedTrendName: {
    //   immediate: true,
    //   handler(newValue, oldValue) {
    //     if (newValue && oldValue !== newValue) {
    //       this.fetchWarnings()
    //     }
    //   },
    // },
    dates(newValue, oldValue) {
      if(newValue && newValue !== oldValue) {
        this.date = {
          from: dayjs(newValue[0] || new Date()).valueOf(),
          to: dayjs(newValue[1] || new Date()).valueOf()
        }
        this.fetchByDate()
      }
    },
  },
  mounted() {
    this.FetchMessages(this.device_id).then(() => this.handleDatesChange(1, 'day'))
    this.selected_type = {
      vel: 'RMS(v)',
      acc: 'RMS(a)'
    }[this.$route.meta['type'] ?? 'vel']
  },
  beforeDestroy() {
    this.cancelRequests.cancel("canceled")
  },
  methods: {
    ...mapMutations(["ChangeNavName"]),
    ...mapActions(["FetchMessages"]),
    async fetchTrends() {
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trends`, {
          ...this.date,
          type: this.selected_type,
          ...(this.selectedTrendNameIds?.length > 0 && { name_ids: this.selectedTrendNameIds })
        },
        { cancelToken: this.cancelRequests.token },
        )
        return data || []
      } catch (e) {
        throw new Error(e)
      }
    },

    async fetchTrendNames() {
      this.namesLoading = true
      this.selectedTrendName = undefined

      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-names`, { type: this.selected_type })
        this.trendNames = data?.data || []
        this.selectedTrendName = data?.data?.[0]?.id || ""
      } catch (e) {
        throw new Error(e)
      }

      this.namesLoading = false
    },
    onLegendSelected(value) {
      const legends = value?.map(legend => legend.name)
      this.selectedTrendNameIds = this.trendNames?.filter(name => legends.includes(name.name)).map(name => name.id)

      if(this.selectedTrendNameIds?.length) {
        if(this.selectedTrendNameIds[0] !== this.selectedTrendName) {
          this.selectedTrendName = this.selectedTrendNameIds[0]
          this.fetchWarnings()
        }
      }
    },
    handleDatesChange(value, unit) {
      const from = dayjs().subtract(value, unit)
      const to = dayjs()
      this.dates = [new Date(from.format()), new Date(to.format())]
    },

    async fetchByDate() {
      this.isLoading = true
      await this.fetchTrendNames()
      const warnings = await this.fetchWarnings()

      try {
        await Promise.all([this.fetchTrends(), this.fetchTemperatures(), this.fetchPredictions()])
          // await Promise.all([this.fetchTrends(), this.fetchTemperatures()])
          .then(([trends, temperatures, predictions]) => {
            const { data: temp_data, names } = { ...temperatures }
            const { data: pred_data, channels } = { ...predictions }
            const { data: agent_data, names: agent_names } = { ...trends }

            const grouped_data = (agent_data || []).map(elem => {
              return {
                ...elem,
                ...(temp_data || []).find(tElem => tElem.time === elem.time)
              }
            })

            // let grouped_data = {}
            // const grouped_data = [...agent_data, ...(temp_data || [])]
            // for (const item of all_data) {
            //
            //   if(grouped_data[item.time]) {
            //     grouped_data[item.time] = { ...grouped_data[item.time], ...item }
            //   }
            //
            //   grouped_data[item.time] = item
            // }
            //
            // grouped_data = Object.entries(grouped_data).map(([key, value]) => {
            //   return {
            //     time: key,
            //     ...value
            //   }
            // })

            // console.log(grouped_data)

            this.graph_data = [...grouped_data, ...warnings]
            this.graph_data_with_predictions = [...grouped_data, ...pred_data, ...warnings]
            this.graph_data_without_warnings = [...grouped_data]
            // this.graph_data = [...grouped_data, ...warnings]
            // this.graph_data_without_warnings = [...grouped_data]
            this.agents         = agent_names || []
            this.temperatures   = names || []
            this.predictions    = pred_data || []
            this.predictionChannels = (channels || []).filter(ch => ch !== 'SPEED')
          })
      } catch (e) {
        throw new Error(e)
      }

      this.isLoading = false
      this.checkedPredictionNames = []
    },

    async fetchTemperatures() {
      if(this.showTemperatureText) {

        try {
          const { data } = await this.$axios.post(`user/agents/${this.device_id}/temperatures`, this.date,
            { cancelToken: this.cancelRequests.token })
          return data || {}
        } catch (e) {
          throw new Error(e)
        }
        // await this.$axios.post(`user/agents/${this.device_id}/temperatures`, this.date, { cancelToken: this.cancelRequests.token })
        //   .then(response => {
        //     return response.data
        //     // this.temperatures = response.data
        //   })
      }
    },

    async fetchWarnings() {
      if (this.selectedTrendName && this.device_id && this.levels.length !== 0) {
        try {
          const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-warnings`, {
            ...this.date,
            name_id: this.selectedTrendName,
          }, { cancelToken: this.cancelRequests.token })
          if(this.graph_data_without_warnings.length > 0 && data?.data)
            this.graph_data = [
              ...this.graph_data_without_warnings,
              ...(data?.data ? data.data : [])
            ]
          return data?.data || []
        } catch (e) {
          throw new Error(e)
        }
      }
      return []
    },

    // async fetchMessages() {
    //   try {
    //     const { data } = await this.$axios.get(`user/agents/${this.device_id}/last-message`, { cancelToken: this.cancelRequests.token })
    //     this.messages = data?.data || []
    //     this.buttonsCount = data?.data?.module_count || 4
    //     this.showTemperatureText = data?.data?.temperature_count > 0
    //   } catch (e) {
    //     throw new Error(e)
    //   }
    // },

    async fetchPredictions() {
      // this.isLoading = true;
      this.checkedPredictionNames = []
      // if (this.selected_type === 'RMS(a)') {
      //   return {
      //     data: [],
      //     channels: []
      //   }
      // } else {
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/predictions`, {
          to: Date.now(),
          type: this.selected_type,
          prediction_type: this.type,
        }, { cancelToken: this.cancelRequests.token })
        // if(this.predictions.length === 0) this.predictions = data?.data || []
        // if(this.predictionChannels.length === 0) this.predictionChannels = data?.channels || []
        return data || []
      } catch (e) {
        throw new Error(e)
      }
      // }
      // this.isLoading = false;
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
#chartdiv {
  width: 100%;
  height: 600px;
}

.highlight {
  background: #61a937;
}

.warning {
  position: relative;
}

.line-warning {
  position: absolute;
  top: 12px;
  right: -60px;
  width: 60px;
  border: 2px dashed red;
}

.line-alarm {
  position: absolute;
  top: 12px;
  right: -60px;
  width: 60px;
  border: 2px solid red;
}
</style>
